/**
 * Created by Max Gornostayev on 05/01/22
 *
 * path: /auth/passwort?token={token}
 * id: authSignupStepFour
 *
 * this is a page that shows fourth-step registration form, we confirm email on this page and set the password.
 * To confirm password we need to get token from param and send to the server
 *
 */

import React, { useRef, useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';

import Loading from '../../components/elements/Loading';
import Alert from '../../components/popups/Alert';
import logoFull from '../../assets/logoFull.svg';
import InputText from '../../components/V2/InputText';
import Button from '../../components/V2/Button';
import Validation from '../../lib/Validation';
import pageUrl from '../../const/pages';
import trans from '../../trans';

const SignupStepFourPage = observer(({ UserStore, ContractsStore }) => {
    const { customer } = UserStore;
    //state variables
    const [isLoading, setIsLoading] = useState(false);
    const [password, setPassword] = useState('');
    const [emailPassToken, setEmailPassToken] = useState('');
    const [isEmailConfirmed, setIsEmailConfirmed] = useState(false);

    //ref variables
    const refAlert = useRef();
    const refPassword = useRef();
    const refRePassword = useRef();

    //navigate variable that is used to go to another screen
    const navigate = useNavigate();

    //get prop 'tokenProp' from another screen
    const { state } = useLocation();

    //get search query object
    const [searchParams] = useSearchParams();

    const email = searchParams.get('email') || '';
    const companyValue = searchParams.get(process.env.REACT_APP_CUSTOMER_COMPANY_KEY) || '';
    const isCompany = companyValue === process.env.REACT_APP_CUSTOMER_COMPANY_VALUE;

    //confirm email if we have token param inside query of the url
    useEffect(() => {
        const asyncFunc = async () => {
            const emailToken = searchParams.get('token');
            if (emailToken) {
                const res = await UserStore.emailConfirm(emailToken);
                if (res.status) {
                    setEmailPassToken(res.data.passwordToken);
                    setIsEmailConfirmed(true);
                    refAlert.current.show(trans.t('signupStepFourPage', 'successMsg'));
                } else {
                    refAlert.current.error(res.msg);
                }
            }
        };

        asyncFunc();
    }, []);

    //button handler
    const next = async () => {
        if (isCompany && !isEmailConfirmed) {
            refAlert.current.error(trans.t('signupStepFourPage', 'emailNotConfirmed'));
            return false;
        }

        const validObj = refPassword.current.validate();
        if (!validObj.isValid) {
            refAlert.current.error(validObj.validateMsg);
            return false;
        }

        const validObjRePass = refRePassword.current.validate();
        if (!validObjRePass.isValid) {
            refAlert.current.error(validObjRePass.validateMsg);
            return false;
        }

        setIsLoading(true);

        //get token from query string, if it's passing from previous step
        const tokenProp = state && state.hasOwnProperty('token') ? state.token : '';
        const token = emailPassToken || tokenProp;

        const res = await UserStore.updatePassword(password, token);
        setIsLoading(false);
        if (!res.status) {
            refAlert.current.error(res.msg);
            return false;
        }
        UserStore.clearStore();
        ContractsStore.clearStore();
        UserStore.setIsCompany(isCompany);
        navigate(pageUrl.authSigninPage, { state: { email, phone: customer.phoneTitle } });
    };

    return (
        <div className="main-wrapper login-page login-background">
            <div className="main-content-new top-padding">
                <img src={logoFull} alt="logo" className="logo-full" />
                <div className="subtitle">
                    {trans.t('signupStepFourPage', 'h1')}
                </div>
                <div className="form-wrapper">
                    <InputText
                        placeholder={trans.t('signupStepFourPage', 'phoneNumber')}
                        type="text"
                        defaultValue={customer.phoneTitle}
                    />
                    <InputText
                        ref={refPassword}
                        role="passwordTestId"
                        placeholder={trans.t('signupStepFourPage', 'password')}
                        type="password"
                        onChange={(val) => setPassword(val)}
                        validateFunc={(val) => Validation.password(val)}
                    />
                    <InputText
                        ref={refRePassword}
                        role="rePasswordTestId"
                        placeholder={trans.t('signupStepFourPage', 'rePassword')}
                        type="password"
                        validateFunc={(val) => Validation.passwordConfirm(val, password)}
                    />
                    <Button
                        green
                        title={trans.t('signupStepFourPage', 'btnNext')}
                        onClick={next}
                    />
                </div>
            </div>
            <Alert ref={refAlert} />
            <Loading isShow={isLoading} />
        </div>
    );
});

export default SignupStepFourPage;
