import React from 'react';
import Header from '../../components/V2/Header';
import Button from '../../components/V2/Button';
import PageWrapper from '../../components/V2/PageWrapper';
import trans from '../../trans';
import '../../styles/V1/app.scss';

const BusinessPartnerWerden = () => {
    return (
        <PageWrapper
            contentClassName="business-partner-werden"
            header={<Header backButton title={trans.t('partnerPage', 'title')} noMenu />}
        >
            <div className="gray-box">
                <div className="description" data-i18n="[html]content.body">
                    {trans.t('partnerPage', 'description')}
                </div>
            </div>

            <div className="video-section">
                <div className="title">{trans.t('partnerPage', 'videoTitle')}</div>
                <iframe
                    width="100%"
                    height="200"
                    src="https://www.youtube.com/embed/IB5qg81T8hg"
                    title="Business partner werden"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen
                />
            </div>

            <ul className="benefits-list">
                <li><span>{trans.tHTML('partnerPage', 'maximizeIncome')}</span></li>
                <li><span>{trans.tHTML('partnerPage', 'simpleManagment')}</span></li>
                <li><span>{trans.tHTML('partnerPage', 'simpleManagment')}</span></li>
            </ul>

            <div className="action-section">
                <Button
                    green
                    className="button-max-width"
                    title={trans.t('termsPage', 'btnNext')}
                />
                <div className="button-subtitle">{trans.t('partnerPage', 'price')}</div>
            </div>
        </PageWrapper>
    );
};

export default BusinessPartnerWerden;
