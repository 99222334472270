/**
 * Created by Max Gornostayev on 04/30/22
 *
 * path: /auth/email-verifizieren
 * id: authSignupStepThree
 *
 * this is a page that shows third-step registration form, after this page we need go to the fourth-step
 * registration page where we must enter password and check it
 */

import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useNavigate, useLocation } from 'react-router-dom';
import { timer } from 'rxjs';
import Header from '../../components/theme/HeaderV2';
import Bottom from '../../components/theme/Bottom';
import BottomLinks from '../../components/theme/BottomLinks';
import ButtonV2 from '../../components/V2/Button';
import SignupSteps from '../../components/elements/SignupSteps';
import Tooltip from '../../components/V2/Tooltip';
import pageUrl from '../../const/pages';
import trans from '../../trans';
import '../../styles/V1/app.scss';
import '../../styles/V1/terms.scss';
import '../../styles/V1/auth.scss';

const SignupStepThreePage = observer(({ UserStore }) => {
    const { customer, isCompany } = UserStore;

    //state variables
    const [isEmailNotSent, setIsEmailNotSent] = useState(false);

    //get prop 'emailToken' and 'passwordToken' from another screen
    const { state } = useLocation();
    const emailToken = state && state.hasOwnProperty('emailToken') ? state.emailToken : '';
    const passwordToken = state && state.hasOwnProperty('passwordToken') ? state.passwordToken : '';

    //navigate variable that is used to go to another screen
    const navigate = useNavigate();

    //subscription
    let subscription = null;

    //start countdown when page is mounted
    useEffect(() => {
        if (!UserStore.isCompany) {
            subscription = timer(200).subscribe(() => sendConfirmation());
        }

        return () => {
            if (subscription && subscription.unsubscribe) subscription.unsubscribe();
        };
    }, []);

    //send confirmation email
    const sendConfirmation = async () => {
        if (!isEmailNotSent) {
            const res = await customer.sendEmailConfirmation(emailToken);
            if (!res.status) {
                setIsEmailNotSent(true);
            }
        }
    };

    //send confirmation email
    const ctaClick = () => navigate(pageUrl.authSignupStepFour, { state: { token: passwordToken } });

    return (
        <div className="app auth signup-step-1-page">
            <Header backButton noMenu title={trans.t('signupStepThreePage', 'h1')} />
            <div className="content">
                <div className="wrapper-big">
                    <Tooltip position="top">
                        {trans.t('signupStepThreePage', 'expertMsg')}
                    </Tooltip>
                    <SignupSteps currentStep={3} />
                    <div className="paragraphs-wrapper">
                        <div className="paragraph-item">
                            <div className="paragraph-number">01</div>
                            <div className="paragraph-content">
                                <div className="paragraph-title">{trans.t('signupStepThreePage', 'titleLinkEmail')}</div>
                                <div className="paragraph-text">{trans.t('signupStepThreePage', 'descLinkEmail')}</div>
                            </div>
                        </div>
                        <div className="paragraph-item">
                            <div className="paragraph-number">02</div>
                            <div className="paragraph-content">
                                <div className="paragraph-title">{trans.t('signupStepThreePage', 'titleEmailLetter')}</div>
                                <div className="paragraph-text">{trans.t('signupStepThreePage', 'descEmailLetter')}</div>
                            </div>
                        </div>
                        <div className="paragraph-item">
                            <div className="paragraph-number">03</div>
                            <div className="paragraph-content">
                                <div className="paragraph-title">{trans.t('signupStepThreePage', 'titleCreatePassw')}</div>
                                <div className="paragraph-text">{trans.t('signupStepThreePage', 'descCreatePassw')}</div>
                            </div>
                        </div>
                        <div className="paragraph-item">
                            <div className="paragraph-number">04</div>
                            <div className="paragraph-content">
                                <div className="paragraph-title">{trans.t('signupStepThreePage', 'titleViewContract')}</div>
                                <div className="paragraph-text">{trans.t('signupStepThreePage', 'descViewContract')}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {!isCompany && (
                <Bottom>
                    <ButtonV2
                        title={trans.t('signupStepThreePage', 'btnNext')}
                        onClick={ctaClick}
                        green
                    />
                </Bottom>
            )}
            <BottomLinks />
        </div>
    );
});

export default SignupStepThreePage;
