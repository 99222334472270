const objModel = {
    customer: {
        customerId: 0,
        customerTempID: '',
        customerType: 'lead',
        salutation: '',
        firstName: '',
        lastName: '',
        birthdate: null,
        position: '',
        accountType: 'private',
        sumPayedTokenValues: 0,
        businessPartnershipStatus: false,
    },
    address: {
        addressType: 'private',
        street: '',
        postalCode: '',
        city: '',
        country: '',
    },
    communication: {
        email: '',
        emailType: 'private',
        emailVerified: false,
        phoneCode: '',
        phoneNumber: '',
        phoneType: 'mobile',
        phoneVerified: false,
    },
    terms: {
        acceptPrivacyTermsAt: '',
        acceptBrokerInformationAt: '',
        acceptTermsOfUseAt: '',
        acceptEnergyBrokerInformationAt: '',
    },
    affiliate: {
        affiliateID: '',
        referalID: '',
    },
    company: {
        customerId: 0,
        companyName: '',
        taxNumber: '',
        customerType: 'lead',
    },
    contactPerson: {
        salutation: '',
        firstName: '',
        lastName: '',
        birthdate: '',
        position: '',
    },
};

// const objModel = {
//     customer: {
//         customerId: 0,
//         customerTempID: 'be8d903c-57c3-4336-8756-ffa4afe5fd0e',
//         customerType: 'lead',
//         salutation: 'mr',
//         firstName: 'Max',
//         lastName: 'Service',
//         birthdate: '1982-01-01',
//     },
//     address: {
//         addressType: 'private',
//         street: 'street 123',
//         postalCode: '12345',
//         city: 'London',
//         country: '',
//     },
//     communication: {
//         email: 'asd@asd.com',
//         emailType: 'private',
//         emailVerified: false,
//         phoneCode: '0049',
//         phoneNumber: '12333345',
//         phoneType: 'mobile',
//         phoneVerified: false,
//     },
//     terms: {
//         acceptPrivacyTermsAt: '2022-06-12',
//         acceptBrokerInformationAt: '2022-06-12',
//         acceptTermsOfUseAt: '2022-06-12',
//     },
//     affiliate: {
//         affiliateID: '',
//         referalID: '',
//     },
// };

export default objModel;
