/**
 * Created by Max Gornostayev on 02/15/22
 *
 * this is a page for home page
 */

import { makeAutoObservable, action, runInAction } from 'mobx';
import { makePersistable } from 'mobx-persist-store';
import Sync from '../core/Sync';
import Product from '../models/list/Product';
import Provider from '../models/list/Provider';
import BrokerStatus from '../models/list/BrokerStatus';
import ContractStatus from '../models/list/ContractStatus';
import PaymentMethod from '../models/list/PaymentMethod';
import Salutation from '../models/list/Salutation';
import syncLists from '../const/syncLists';
import syncData from '../const/syncData';
import Utils from '../lib/Utils';
import config from '../config';

class DataStore {
    cache = '';

    products = new Map();

    providers = new Map();

    brokerStatuses = new Map();

    contractStatuses = new Map();

    paymentMethods = new Map();

    salutations = new Map();

    ContractsFilter = '';

    /*
     * is store loaded
     */
    isLoaded = true;

    /*
     * This is constructor
     */
    constructor() {
        makeAutoObservable(this);

        //load lists data from local file
        this.loadDataFromFile();
        //set store as loaded as we get data from local file, and we don't need to wait when data is loaded from server
        this.setIsLoaded(true);

        makePersistable(this, {
            name: 'BancassuranceDataStore',
            properties: ['cache'],
            storage: window.localStorage,
            removeOnExpiration: true,
            expireIn: config.storesExpiretion.data,
            debugMode: false,
        }).then(
            action(async () => {
                await this.loadData();
            })
        );
    }

    /*
     * Get salutation list for select
     */
    get salutationList() {
        const ret = [];
        for (const [, salutation] of this.salutations) {
            ret.push({ value: salutation.serviceplatformIdValue, label: salutation.titleValue });
        }
        return ret;
    }

    /*
     * Get salutation list for select
     */
    get contractStatusList() {
        const ret = [];
        for (const [, status] of this.contractStatuses) {
            ret.push({ value: status.serviceplatformIdValue, label: status.titleValue });
        }
        return ret;
    }

    /*
     * get array of store names
     */
    get storeNames() {
        const arr = [];
        Object.values(syncLists).map((obj) => arr.push(obj.storeName));
        return arr;
    }

    get contractFilter() {
        return this.ContractsFilter;
    }

    setContractsFilter(value) {
        this.ContractsFilter = value;
    }

    /*
     * set loaded store or no
     */
    setIsLoaded(value) {
        runInAction(() => {
            this.isLoaded = value;
        });
    }

    /*
     * load data
     */
    async loadData() {
        const isCacheLoaded = this.loadFromCache();
        const isCached =
            isCacheLoaded &&
            !!this.products.size &&
            !!this.providers.size &&
            !!this.brokerStatuses.size &&
            !!this.contractStatuses.size &&
            !!this.paymentMethods.size &&
            !!this.salutations.size;

        // eslint-disable-next-line no-constant-condition
        if (!isCached) {
            //load(sync) data from the server
            if (config.env !== 'local') {
                await this.loadDataFromServer();

                //----------------------------------------------
                //-------------DO NOT REMOVE--------------------
                // TODO - make a script to generate cache object
                // next two lines is for generating cache object, uncomment if you want to generate a cache object. And then copy/paste it to ./scr/const/syncData.js
                // const obj = this.createCacheObject();
                // console.log(obj);
                //----------------------------------------------
                //----------------------------------------------
            }
            this.updateCache();
        }
    }

    /*
     * load cache data from local app
     */
    loadDataFromFile() {
        const { storeNames } = this;
        storeNames.map((name) => {
            if (syncData.hasOwnProperty(name)) {
                syncData[name].map((obj) => this.setDataObj(name, obj));
            }
        });
    }

    /*
     * load from cache string, return true if cache is loaded
     */
    loadFromCache() {
        try {
            const { cache } = this;
            if (cache) {
                const obj = JSON.parse(this.cache);
                const { storeNames } = this;
                storeNames.map((name) => {
                    if (obj.hasOwnProperty(name)) {
                        this[name] = new Map();
                        obj[name].map((obj) => this.setDataObj(name, obj));
                    }
                });
                return true;
            }
        } catch (e) {
            return false;
        }
        return false;
    }

    /*
     * update cache string
     */
    updateCache() {
        const obj = this.createCacheObject();
        this.cache = JSON.stringify(obj);
    }

    /*
     * set data object depending on name
     */
    setDataObj(name, obj, syncObj) {
        const classObj = this.getClassObj(name, obj, syncObj);
        if (classObj.idValue) {
            this[name].set(classObj.idValue, classObj);
        }
    }

    /*
     * get data object depending on name
     */
    getClassObj(name, obj, syncObj) {
        switch (name) {
            case 'products':
                return new Product({ obj, syncObj });
            case 'providers':
                return new Provider({ obj, syncObj });
            case 'brokerStatuses':
                return new BrokerStatus({ obj, syncObj });
            case 'contractStatuses':
                return new ContractStatus({ obj, syncObj });
            case 'paymentMethods':
                return new PaymentMethod({ obj, syncObj });
            case 'salutations':
                return new Salutation({ obj, syncObj });
            default:
                return {};
        }
    }

    /*
     * load cache data from server
     */
    async loadDataFromServer() {
        // TODO - remove after add products to server
        this.products.set('power', new Product({
            obj: {},
            syncObj: {
                id: 1234567,
                techId: '30662',
                fields: {
                    PROPERTY_1504: 'Strom hinzufügen',
                    PROPERTY_1505: 'power',
                    PROPERTY_1496: '55555',
                    PROPERTY_1493: '716'
                },
                name: 'Strom hinzufügen'
            },
        }));
        this.products.set('gas', new Product({
            obj: {},
            syncObj: {
                id: 1234568,
                techId: '30663',
                fields: {
                    PROPERTY_1504: 'Gas hinzufügen',
                    PROPERTY_1505: 'gas',
                    PROPERTY_1496: '55555',
                    PROPERTY_1493: '716'
                },
                name: 'Gas hinzufügen'
            },
        }));

        const { storeNames } = this;
        for (let i = 0; i < storeNames.length; i++) {
            const setObjFunc = (syncObj) => this.setDataObj(storeNames[i], {}, syncObj);
            await Sync.getAllItems(storeNames[i], setObjFunc);
        }
    }

    /*
     * Get product list by filter
     * @var search - searching string
     * @var isCompany - providers for company or private type of account
     */
    getProductsListByFilter(search, isCompany) {
        const products = [];
        const categories = isCompany ? ['26664'] : ['26662', '26665', '26667', '26669'];
        for (const [, product] of this.products) {
            if (product.idValue && product.titleValue && categories.some((v) => product.checkCategory(v))) {
                products.push({ id: product.idValue, title: product.titleValue });
            }
        }
        return Utils.getSearchableList(products, search);
    }

    /*
     * Get provider list by filter
     */
    getProvidersListByFilter(categories, search) {
        const providers = [];
        for (const [, provider] of this.providers) {
            if (provider.checkCategory(categories)) {
                if (provider.serviceplatformIdValue && provider.titleValue) {
                    providers.push({ id: provider.idValue, title: provider.titleValue });
                }
            }
        }
        return Utils.getSearchableList(providers, search);
    }

    /*
     * Get product title
     */
    getProductTypeTitle(productId) {
        if (this.products.has(productId)) {
            const product = this.products.get(productId);
            return product.titleValue;
        }
        return '';
    }

    /*
     * Get provider obj
     */
    getProduct(productId) {
        if (!productId || !this.products.has(productId)) {
            return new Product({});
        }
        const product = this.products.get(productId);
        return product;
    }

    /*
     * Get provider obj
     */
    getProvider(providerId) {
        if (!providerId || !this.providers.has(providerId)) {
            return new Provider({});
        }
        const provider = this.providers.get(providerId);
        return provider;
    }

    /*
     * Get provider title
     */
    getProviderTitle(providerId) {
        if (!providerId) {
            return '';
        }
        if (this.providers.has(providerId)) {
            const provider = this.providers.get(providerId);
            return provider.titleValue;
        }
        return providerId;
    }

    /*
     * Get provider logo url
     */
    getProviderLogoUrl(providerId) {
        if (!providerId) {
            return '';
        }
        if (this.providers.has(providerId)) {
            const provider = this.providers.get(providerId);
            return provider.logoUrl;
        }
        return '';
    }

    /*
     * Get provider title
     */
    getContractStatusTitle(contractStatusId) {
        if (!contractStatusId) {
            return '';
        }
        if (this.contractStatuses.has(contractStatusId)) {
            const contractStatus = this.contractStatuses.get(contractStatusId);
            return contractStatus.titleValue;
        }
        return '';
    }

    /*
     * Get product title
     */
    getIsPossibleBroker(productId, providerId) {
        if (this.products.has(productId) && this.providers.has(providerId)) {
            const product = this.products.get(productId);
            const provider = this.providers.get(providerId);
            return product.isBroker && provider.isBroker;
        }
        return false;
    }

    /*
     * Get product title
     */
    getPaymentMethodTitle(paymentMethodId) {
        if (this.paymentMethods.has(paymentMethodId)) {
            const paymentMethod = this.paymentMethods.get(paymentMethodId);
            return paymentMethod.titleValue;
        }
        return '';
    }

    /*
     * Get salutation title
     */
    getSalutationTitle(salutationId) {
        if (this.salutations.has(salutationId)) {
            const salutation = this.salutations.get(salutationId);
            return salutation.titleValue;
        }
        return '';
    }

    /*
     * Get element id from list
     */
    getListElementIdFromFinAPI(elType, elFinApiId) {
        let ret = '';

        const findElement = (name, finapiId) => {
            let id = '';
            for (const [, obj] of this[name]) {
                if (finapiId === obj.finapiIdValue) {
                    id = obj.idValue;
                    break;
                }
            }
            return id;
        };

        switch (elType) {
            case 'product':
                ret = findElement('products', elFinApiId);
                break;
            case 'provider':
                ret = findElement('providers', elFinApiId);
                break;
            case 'status':
                ret = findElement('contractStatuses', elFinApiId);
                break;
            case 'paymentMethod':
                ret = findElement('paymentMethods', elFinApiId);
                break;
            default:
                ret = elFinApiId;
        }

        return ret;
    }

    /*
     * Get element id from list
     */
    getServiceplatformIdById(elType, elId) {
        let ret = '';

        const findElement = (name, elemid) => {
            let id = '';
            for (const [, obj] of this[name]) {
                if (elemid === obj.id) {
                    id = obj.serviceplatformIdValue;
                    break;
                }
            }
            return id;
        };

        switch (elType) {
            case 'product':
                ret = findElement('products', elId);
                break;
            case 'provider':
                ret = findElement('providers', elId);
                break;
            case 'status':
                ret = findElement('contractStatuses', elId);
                break;
            case 'paymentMethod':
                ret = findElement('paymentMethods', elId);
                break;
            default:
                ret = elId;
        }

        return ret;
    }

    getProductsByCategories(categories) {
        return Array.from(this.products.values()).filter((product) => categories.some((v) => product.checkCategory(v)))
            .map((product) => product.serviceplatformId);
    }

    /*
     * create cache object
     */
    createCacheObject() {
        const { storeNames } = this;
        const retObj = {};
        storeNames.map((name) => {
            retObj[name] = [];
            for (const [, obj] of this[name]) {
                retObj[name].push(obj.serverObject);
            }
        });
        return retObj;
    }
}

export default DataStore;
