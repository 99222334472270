/**
 * Created by Max Gornostayev on 02/15/22
 *
 * this is API requests for contracts
 */

import AuthAPI from '../AuthAPI';

const API = {
    /*
     * create contracts
     * postman - https://go-digital-software.postman.co/workspace/Team-Workspace~c9b5c7f5-0818-41cf-8221-8ca02d3f072a/example/18672024-b15a7dd2-749e-4f30-874d-34383e4c8377
     */
    createMulti: async (contracts) => {
        const res = await AuthAPI.request('post', '1', '/contract', contracts);
        return res;
    },

    /*
     * update contract
     * @obj - contract object - https://go-digital-software.postman.co/workspace/Team-Workspace~c9b5c7f5-0818-41cf-8221-8ca02d3f072a/request/18672024-522d0a13-3234-4904-bf23-b60e3a90f12e
     */
    update: async (obj) => {
        const res = await AuthAPI.request('patch', '1', '/contract', obj);
        return res;
    },

    /*
     * delete contract
     * @contractId - id of the contract - https://go-digital-software.postman.co/workspace/Team-Workspace~c9b5c7f5-0818-41cf-8221-8ca02d3f072a/request/18672024-e0dcaedb-579e-4793-a53e-1c52d2963a39
     */
    remove: async (contractId) => {
        const res = await AuthAPI.request('delete', '1', '/contract', { contractId });
        return res;
    },

    /*
     * get contracts
     * @example - https://go-digital-software.postman.co/workspace/Team-Workspace~c9b5c7f5-0818-41cf-8221-8ca02d3f072a/example/18672024-087e542c-0a75-45e9-83b2-bc3691c602b0
     */
    getContracts: async () => {
        const res = await AuthAPI.request('get', '1', '/contracts');
        return res;
    },

    /*
     * create contracts
     * @userObject - document object - https://go-digital-software.postman.co/workspace/Team-Workspace~c9b5c7f5-0818-41cf-8221-8ca02d3f072a/request/18672024-6e052a5f-c2c2-44da-b4ef-8485f62d93f1
     */
    saveDocument: async (obj) => {
        const res = await AuthAPI.request('post', '1', '/contract/document', obj);
        return res;
    },

    /*
     * get documents
     * @example - https://go-digital-software.postman.co/workspace/Team-Workspace~c9b5c7f5-0818-41cf-8221-8ca02d3f072a/example/18672024-91486bd5-9efc-44af-9ec4-a3325d14528e
     */
    getDocuments: async (contractId) => {
        const res = await AuthAPI.request('get', '1', `/contract/${contractId}/documents`);
        return res;
    },

    /*
     * delete document
     * @example - https://go-digital-software.postman.co/workspace/Team-Workspace~c9b5c7f5-0818-41cf-8221-8ca02d3f072a/request/18672024-1ade9be6-0e22-4499-8ca9-bf7156f17a1d
     */
    deleteDocument: async (documentId) => {
        const res = await AuthAPI.request('delete', '1', '/contract/document', { documentId });
        return res;
    },

    /*
     * delete document
     * @example - https://go-digital-software.postman.co/workspace/Team-Workspace~c9b5c7f5-0818-41cf-8221-8ca02d3f072a/request/18672024-1ade9be6-0e22-4499-8ca9-bf7156f17a1d
     */
    getDocument: async (documentId) => {
        const res = await AuthAPI.request('get', '1', `/contract/document/${documentId}`);
        return res;
    },

    /*
     * activate broker for contracts
     * @example - https://go-digital-software.postman.co/workspace/Team-Workspace~c9b5c7f5-0818-41cf-8221-8ca02d3f072a/example/18672024-1425406a-b1ba-4f8b-b47d-6f0dff74ac44
     */
    activateBrokerForContracts: async (checkedIds, brokerType, customerId) => {
        const res = await AuthAPI.request('post', '1', '/broker/contracts/activate', { contractIds: checkedIds, brokerType, customerId });
        return res;
    },
};

export default API;
