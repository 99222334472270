import React from 'react';

import connunityLogo from '../../assets/V2/conunityLogo.svg';
import iconService from '../../assets/V2/iconService.svg';
import menu from '../../assets/header/menu.svg';
import chevronLeft from '../../assets/V2/chevronLeft.svg';
import SlideMenu from '../theme/SlideMenu';
import { useNavigate } from 'react-router-dom';

const Header = ({ backButton, title, noMenu, backFunc }) => {
    const navigate = useNavigate();
    const [isOpenedSlide, setIsOpenedMenu] = React.useState(false);

    const openSlideMenu = () => {
        setIsOpenedMenu(!isOpenedSlide);
    };

    return (
        <div className="header-wrapper-new">
            {!backButton && <img src={connunityLogo} className="header-logo" />}
            {backButton && (
                <div className="back-wrapper" onClick={backFunc || (() => navigate(-1))}>
                    <img src={chevronLeft} />
                    <div className="header-title">{title}</div>
                </div>
            )}
            {!noMenu && (
                <>
                    <div className="right-side">
                        <img src={iconService} className="right-side-icon" />
                        <img src={menu} className="right-side-icon menu-icon" onClick={openSlideMenu} />
                    </div>
                    <SlideMenu isOpened={isOpenedSlide} closeFunc={() => setIsOpenedMenu(false)} />
                </>
            )}
        </div>
    );
};

export default Header;
