/**
 * Created by Max Gornostayev on 02/15/22
 *
 * this is a component that show search input element
 */

import React, { useState } from 'react';
import trans from '../../trans';

export default function SearchInput({ onChange, defaultValue, black }) {
    //state variables
    const [value, setValue] = useState(defaultValue || '');

    //onchange handler
    const onChangeHandler = (event) => {
        setValue(event.target.value);

        if (onChange) {
            onChange(event.target.value);
        }
    };

    return (
        <div className={`search-input-element ${black ? 'black' : ''}`}>
            <input type="text" placeholder={trans.t('common', 'textSearch')} value={value} onChange={onChangeHandler} />
        </div>
    );
}
