/**
 * Created by Max Gornostayev on 02/15/22
 *
 * this is main component for application
 */

import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { reaction } from 'mobx';
import { useLocalObservable, MobXProviderContext } from 'mobx-react';
import API from './api';
import config from './config';
import ScrollToTop from './components/elements/ScrollToTop';
import Loading from './components/elements/Loading';
import RouteAuth from './core/RouteAuth';
import RouteSecure from './core/RouteSecure';
import RouteOnboarding from './core/RouteOnboarding';
import pageUrl from './const/pages';
import NotFoundPage from './pages/NotFoundPage';
import HomePage from './pages/HomePage';
import TermsPage from './pages/onboarding/TermsPage';
import OnboardingContractAddPage from './pages/onboarding/ContractAddPage';
import OnboardingContractList from './pages/onboarding/ContractListPage';
import OnboardingProductsPage from './pages/onboarding/ProductsPage';
import OnboardingProvidersPage from './pages/onboarding/ProvidersPage';
import OnboardingContractEditPage from './pages/onboarding/ContractEditPage';
import SignBrokerInsurancePage from './pages/onboarding/SignBrokerInsurancePage';
import OnboardingSignBrokerEnergyPage from './pages/onboarding/SignEnergyBrokerPage';
import UserDataPage from './pages/onboarding/UserDataPage';
import OnboardingSignBrokerPage from './pages/onboarding/SignBrokerPage';
import OnboardingSignPrivacyPage from './pages/onboarding/SignPrivacyPage';
import OnboardingSignPowerAttorneyPage from './pages/onboarding/SignPowerAttorneyPage';
import OnboardingBrokerSuccessPage from './pages/onboarding/BrokerSuccessPage';
import OnboardingFinAPIImportPage from './pages/onboarding/FinAPIImportPage';
import OnboardingFinAPIErrorPage from './pages/onboarding/FinAPIErrorPage';
import OnboardingFinAPIRestartPage from './pages/onboarding/FinAPIRestartPage';
import AuthSignupStepOnePage from './pages/auth/SignupStepOnePage';
import AuthSignupStepTwoPage from './pages/auth/SignupStepTwoPage';
import AuthSignupStepThreePage from './pages/auth/SignupStepThreePage';
import AuthSignupStepFourPage from './pages/auth/SignupStepFourPage';
import AuthForgotEmailSendPage from './pages/auth/ForgotEmailSendPage';
import AuthForgotEmailConfirmPage from './pages/auth/ForgotEmailConfirmPage';
import AuthStartPrivatePage from './pages/auth/StartPrivatePage';
import AuthStartCompanyPage from './pages/auth/StartCompanyPage';
import StartRegisterPage from './pages/auth/StartRegisterPage';
import StartLoginPage from './pages/auth/StartLoginPage';
import ConfirmEmailPage from './pages/auth/ConfirmEmailPage';
import SecureDashboardPage from './pages/secure/DashboardPage';
import SecureContractsListPage from './pages/secure/contract/ContractsListPage';
import SecureContractDetailPage from './pages/secure/contract/ContractDetailPage';
import SecureContractDocumentsPage from './pages/secure/contract/ContractDocumentsPage';
import SecureCustomerProfilePage from './pages/secure/customer/CustomerProfilePage';
import SecureReferralPage from './pages/secure/customer/ReferralPage';
import SharingPage from './pages/secure/SharingPage';
import BusinessPartnerWerden from './pages/secure/BusinessPatnerWerden';
import RootStore from './stores/RootStore';
import CashbackPage from './pages/secure/CashbackPage';
import PaymentPage from './pages/secure/PaymentPage';
import LoginPage from './pages/auth/LoginPage';
import BenefitsPage from './pages/onboarding/BenefitsPage';

const App = () => {
    //state variables
    const rootStore = useLocalObservable(() => new RootStore());
    const [isLoading, setIsLoading] = useState(true);

    //set debug mode if it's needed
    API.setDebug(config.isDebug);

    const finishLoadingData = ({ reactionUser, reactionData, reactionContracts }) => {
        if (rootStore.UserStore.isLoaded && reactionUser) {
            reactionUser.dispose();
        }
        if (rootStore.DataStore.isLoaded && reactionData) {
            reactionData.dispose();
        }
        if (rootStore.ContractsStore.isLoaded && reactionContracts) {
            reactionContracts.dispose();
        }
        if (rootStore.UserStore.isLoaded && rootStore.DataStore.isLoaded && rootStore.ContractsStore.isLoaded) {
            setIsLoading(false);
        }
    };

    reaction(
        () => rootStore.UserStore.isLoaded,
        (v1, v2, reactionUser) => finishLoadingData({ reactionUser })
    );
    reaction(
        () => rootStore.DataStore.isLoaded,
        (v1, v2, reactionData) => finishLoadingData({ reactionData })
    );
    reaction(
        () => rootStore.ContractsStore.isLoaded,
        (v1, v2, reactionContracts) => finishLoadingData({ reactionContracts })
    );

    if (isLoading) {
        return <Loading isShow />;
    }

    return (
        <MobXProviderContext.Provider value={rootStore}>
            <Router>
                <ScrollToTop />
                <Routes>
                    <Route path={pageUrl.root} element={<HomePage {...rootStore} />} />
                    <Route path={pageUrl.page404} element={<NotFoundPage />} />
                    <Route path="*" element={<Navigate replace to={pageUrl.authSigninPage} />} />

                    <Route path={pageUrl.authStartRegister} element={<StartRegisterPage {...rootStore} />} />
                    <Route path={pageUrl.authStartLogin} element={<StartLoginPage {...rootStore} />} />
                    <Route path={pageUrl.authStartPrivate} element={<AuthStartPrivatePage {...rootStore} />} />
                    <Route path={pageUrl.authStartCompany} element={<AuthStartCompanyPage {...rootStore} />} />

                    <Route path={pageUrl.authForgotEmailSend} element={<AuthForgotEmailSendPage {...rootStore} />} />
                    <Route path={pageUrl.authForgotEmailConfirm} element={<AuthForgotEmailConfirmPage {...rootStore} />} />

                    <Route path={pageUrl.onboardingTerms} element={<TermsPage {...rootStore} />} />
                    <Route path={pageUrl.authConfirmEmail} element={<ConfirmEmailPage {...rootStore} />} />
                    <Route path={pageUrl.onboardingBenefits} element={<BenefitsPage {...rootStore} />} />

                    <Route element={<RouteAuth {...rootStore} />}>
                        <Route path={pageUrl.authSignupStepOne} element={<AuthSignupStepOnePage {...rootStore} />} />
                        <Route path={pageUrl.authSignupStepTwo} element={<AuthSignupStepTwoPage {...rootStore} />} />
                        <Route path={pageUrl.authSignupStepThree} element={<AuthSignupStepThreePage {...rootStore} />} />
                        <Route path={pageUrl.authSignupStepFour} element={<AuthSignupStepFourPage {...rootStore} />} />
                        <Route path={pageUrl.authSigninPage} element={<LoginPage {...rootStore} />} />
                    </Route>

                    <Route path={pageUrl.routeOnboarding} element={<RouteOnboarding {...rootStore} />}>
                        <Route path={pageUrl.onboardingContractAdd} element={<OnboardingContractAddPage {...rootStore} />} />
                        <Route path={pageUrl.onboardingContractList} element={<OnboardingContractList {...rootStore} />} />
                        <Route path={pageUrl.onboardingProducts} element={<OnboardingProductsPage {...rootStore} />} />
                        <Route path={pageUrl.onboardingProviders} element={<OnboardingProvidersPage {...rootStore} />} />
                        <Route path={pageUrl.onboardingContractEdit} element={<OnboardingContractEditPage {...rootStore} />} />
                        <Route path={pageUrl.onboardingSignBrokerInsurance} element={<SignBrokerInsurancePage {...rootStore} />} />
                        <Route path={pageUrl.onboardingUserData} element={<UserDataPage {...rootStore} />} />
                        <Route path={pageUrl.onboardingSignBroker} element={<OnboardingSignBrokerPage {...rootStore} />} />
                        <Route path={pageUrl.onboardingSignBrokerEnergy} element={<OnboardingSignBrokerEnergyPage {...rootStore} />} />
                        <Route path={pageUrl.onboardingSignPrivacy} element={<OnboardingSignPrivacyPage {...rootStore} />} />
                        <Route path={pageUrl.onboardingSignPowerAttorney} element={<OnboardingSignPowerAttorneyPage {...rootStore} />} />
                        <Route path={pageUrl.onboardingBrokerSuccess} element={<OnboardingBrokerSuccessPage {...rootStore} />} />
                        <Route path={pageUrl.onboardingFinApiImport} element={<OnboardingFinAPIImportPage {...rootStore} />} />
                        <Route path={pageUrl.onboardingFinApiError} element={<OnboardingFinAPIErrorPage {...rootStore} />} />
                        <Route path={pageUrl.onboardingFinApiRestart} element={<OnboardingFinAPIRestartPage {...rootStore} />} />
                    </Route>

                    <Route element={<RouteSecure {...rootStore} />}>
                        <Route path={pageUrl.secureDashboard} element={<SecureDashboardPage {...rootStore} />} />
                        <Route path={pageUrl.secureContractsList} element={<SecureContractsListPage {...rootStore} />} />
                        <Route path={pageUrl.secureContractDetail} element={<SecureContractDetailPage {...rootStore} />} />
                        <Route path={pageUrl.secureContractDocuments} element={<SecureContractDocumentsPage {...rootStore} />} />
                        <Route path={pageUrl.secureCustomerProfile} element={<SecureCustomerProfilePage {...rootStore} />} />
                        <Route path={pageUrl.secureReferral} element={<SecureReferralPage {...rootStore} />} />
                        <Route path={pageUrl.businessPartnerWerden} element={<BusinessPartnerWerden {...rootStore} />} />
                        <Route path={pageUrl.sharing} element={<SharingPage {...rootStore} />} />
                        <Route path={pageUrl.cashback} element={<CashbackPage {...rootStore} />} />
                        <Route path={pageUrl.cashbackPayment} element={<PaymentPage {...rootStore} />} />
                    </Route>
                </Routes>
            </Router>
        </MobXProviderContext.Provider>
    );
};

export default App;
