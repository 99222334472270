/**
 * Created by Max Gornostayev on 02/15/22
 *
 * pageId: onboardingContractListFinish
 * path: /onboarding/makler/info
 *
 * this is a success page that is shown after selecting contracts
 */

import React, { useRef, useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import SignatureCanvas from 'react-signature-canvas';
import { isMobile } from 'react-device-detect';
import Header from '../../components/theme/HeaderV2';
import Bottom from '../../components/theme/Bottom';
import BottomLinks from '../../components/theme/BottomLinks';
import Button from '../../components/V2/Button';
import pageUrl from '../../const/pages';
import trans from '../../trans';
import Loading from '../../components/elements/Loading';
import Alert from '../../components/popups/Alert';
import '../../styles/V1/app.scss';
import '../../styles/V1/onboarding.scss';
import Tooltip from '../../components/V2/Tooltip';

const SignBrokerInsurancePage = observer(({ UserStore, ContractsStore }) => {
    //navigate variable that is used to go to another screen
    const navigate = useNavigate();
    const refSign = useRef();
    const refAlert = useRef();
    const [isLoading, setIsLoading] = useState(false);
    const [isSigned, setIsSign] = useState(false);

    //get customer
    const { customer } = UserStore;

    //component did mount handler
    useEffect(() => {
        const onWindowResize = () => {
            const canvas = refSign.current.getCanvas();
            canvas.width = canvas.parentElement.clientWidth;
            canvas.height = canvas.parentElement.clientHeight;
        };
        onWindowResize();
        window.addEventListener('resize', onWindowResize);
        return () => window.removeEventListener('resize', onWindowResize);
    }, []);

    const next = async () => {
        //TODO: add flow if user is existing already

        setIsLoading(true);
        let signEnergyBrokerBase64 = refSign.current.getTrimmedCanvas().toDataURL('image/png');

        if (!signEnergyBrokerBase64) {
            setIsLoading(false);
            refAlert.current.error(trans.t('errors', 'firstSignIsEmpty'));
            return false;
        }

        signEnergyBrokerBase64 = signEnergyBrokerBase64.substring(21);

        const res = await UserStore.signBroker({
            signEnergyBrokerBase64,
            contractIds: ContractsStore.checkedIds,
            brokerType: ContractsStore.tempContractType,
        });

        if (!res.status) {
            setIsLoading(false);
            refAlert.current.error(res.msg);
            return false;
        }
        ContractsStore.setCheckedIds([]);
        setIsLoading(false);

        navigate(pageUrl.onboardingContractAdd);
    };

    const removeSign = () => {
        refSign.current.clear();
        setIsSign(false);
    };

    const onStartSign = () => {
        setIsSign(true);
    };

    const canvasProps = { height: 260, className: 'signCanvas' };
    if (isMobile) {
        canvasProps.width = window.innerWidth - 40;
    }

    const signNameText = trans.t('onboardingSignBrokerPage', 'preNameText');

    return (
        <div className="app onboarding-contract-list-finish-page">
            <Header title="Energie Makler" noMenu noIcon backButton />
            <div className="content">
                <div className="page-title">Energie Makler</div>
                <Tooltip>
                    description
                </Tooltip>
                <div className="sign-description">
                    description
                </div>
                <div className="container-sign">
                    <div className="inner">
                        <SignatureCanvas ref={refSign} canvasProps={canvasProps} onBegin={onStartSign} />
                    </div>
                    <div className="container-sign-bottom">
                        <div className="name">
                            {signNameText} {customer.fullName}
                        </div>
                        <div className="remove-sign" onClick={removeSign} />
                    </div>
                </div>
                <Tooltip>
                    description
                </Tooltip>
            </div>
            {isSigned && (
                <Bottom>
                    <Button role="btnNext" title={trans.t('onboardingContractListFinishPage', 'btnNext')} onClick={next} green />
                </Bottom>
            )}
            <BottomLinks />
            <Alert ref={refAlert} />
            <Loading isShow={isLoading} />
        </div>
    );
});

export default SignBrokerInsurancePage;
