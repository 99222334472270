import React from 'react';
import PropTypes from 'prop-types';
import whiteHurt from '../../assets/V2/whiteHurt.svg';

const Tooltip = ({ children, className = '', position = 'bottom', showIcon = true }) => {
    return (
        <div className={`tooltip tooltip--${position} ${className}`}>
            <div className="tooltip__content">
                {showIcon && (
                    <div className="tooltip__icon">
                        <img src={whiteHurt} alt="" />
                    </div>
                )}
                <div className="tooltip__text">
                    {children}
                </div>
            </div>
        </div>
    );
};

Tooltip.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    position: PropTypes.oneOf(['top', 'bottom', 'left', 'right']),
    showIcon: PropTypes.bool
};

export default Tooltip;
