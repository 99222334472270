/**
 * Created by Max Gornostayev on 02/15/22
 *
 * this is a popup for terms
 */

import React from 'react';
import ButtonLink from '../buttons/ButtonLink';
import Button from '../V2/Button';
import TextsFromFile from '../elements/TextsFromFile';
import deTermsTexts from '../../trans/lang/de.terms';
import dePrivacyTexts from '../../trans/lang/de.privacy';
import deInfoTexts from '../../trans/lang/de.info';
import trans from '../../trans';

export default class PopupTerms extends React.Component {
    /*
     * constructor
     */
    constructor(props) {
        super(props);
        this.state = { showed: !!this.props.isShow };
        this.refTerms = React.createRef();
        this.refPrivacy = React.createRef();
        this.refInfo = React.createRef();
    }

    /*
     * public function that is used to show popup
     */
    show(tab) {
        this.setState({ showed: true }, () => {
            switch (tab) {
                case 'terms':
                    this.refTerms.current.scrollIntoView();
                    break;
                case 'info':
                    this.refInfo.current.scrollIntoView();
                    break;
                case 'privacy':
                    this.refPrivacy.current.scrollIntoView();
                    break;
                default:
                    break;
            }
        });
    }

    /*
     * public function that is used to hide popup
     */
    hide() {
        this.setState({ showed: false });
    }

    /*
     * rendering
     */
    render() {
        if (!this.state.showed) {
            return null;
        }
        return (
            <div className="popup popup-terms">
                <div className="container">
                    <div className="container-top">
                        <ButtonLink text={trans.t('termsPopup', 'termsLink')} onClick={() => this.refTerms.current.scrollIntoView()} />
                        <ButtonLink text={trans.t('termsPopup', 'privacyLink')} onClick={() => this.refPrivacy.current.scrollIntoView()} />
                        <ButtonLink text={trans.t('termsPopup', 'infoLink')} onClick={() => this.refInfo.current.scrollIntoView()} />
                    </div>
                    <div className="container-inner">
                        <div className="scroll">
                            <div ref={this.refTerms}>
                                <TextsFromFile texts={deTermsTexts} />
                            </div>
                            <div ref={this.refPrivacy}>
                                <TextsFromFile texts={dePrivacyTexts} />
                            </div>
                            <div ref={this.refInfo}>
                                <TextsFromFile texts={deInfoTexts} />
                            </div>
                        </div>
                    </div>
                    <div className="container-bottom">
                        <Button title={trans.t('termsPopup', 'btnAgree')} onClick={() => this.hide()} />
                    </div>
                </div>
            </div>
        );
    }
}
