const pages = {
    root: '/',
    page404: '/404',
    routeOnboarding: '/onboarding',
    onboardingBenefits: '/onboarding/benefits',
    onboardingTerms: '/onboarding/terms',
    onboardingContractAdd: '/onboarding/vertrag/import',
    onboardingFinApiImport: '/onboarding/vertrag/import-bank',
    onboardingFinApiError: '/onboarding/vertrag/import-bank-error',
    onboardingFinApiRestart: '/onboarding/vertrag/import-bank-restart',
    onboardingContractList: '/onboarding/vertrag/typ',
    onboardingProducts: '/onboarding/vertrag/extra-typ',
    onboardingProviders: '/onboarding/vertrag/anbieter',
    onboardingContractEdit: '/onboarding/vertrag/daten',
    onboardingSignBrokerInsurance: '/onboarding/makler/info',
    onboardingSignBrokerEnergy: '/onboarding/energie-makler/info',
    onboardingUserData: '/onboarding/makler/verifizierung',
    onboardingSignBroker: '/onboarding/makler/sign-maklerauftrag',
    onboardingSignPrivacy: '/onboarding/makler/sign-dsgvo',
    onboardingSignPowerAttorney: '/onboarding/makler/sign-maklervollmacht-unterschreiben',
    onboardingBrokerSuccess: '/onboarding/makler/success',

    routeAuth: '/auth',
    authSignupStepOne: '/auth/aktivieren',
    authSignupStepTwo: '/auth/sms-code',
    authSignupStepThree: '/auth/email-verifizieren',
    authSignupStepFour: '/auth/passwort',
    authSigninPage: '/login',
    authConfirmEmail: '/auth/email/confirm',
    authStartRegister: '/start/register',
    authStartLogin: '/start/login',
    authForgotEmailSend: '/auth/forgot/email',
    authForgotEmailConfirm: '/auth/forgot/email/confirm',
    authStartPrivate: '/register/privat',
    authStartCompany: '/register/company',

    //securitu pages
    secureDashboard: '/dashboard',
    secureContractsList: '/contracts',
    secureContractDetail: '/contract',
    secureContractDocuments: '/contract/documents',
    secureNewContract: '/onboarding/vertrag/import',
    secureNewContractCompany: '/onboarding/vertrag/typ',
    secureQrShare: '/qr-share',
    secureChat: '/',
    secureCustomerProfile: '/profile',
    secureProfile: '/profile',
    secureReferral: '/referral',
    impressum: '/impressum',
    sharing: '/sharing',
    businessPartnerWerden: '/business-partner-werden',
    cashback: '/cashback',
    cashbackPayment: '/cashback-payment',
};

export default pages;
