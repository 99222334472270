import React from 'react';
import { useNavigate } from 'react-router-dom';
import pages from '../../const/pages';

const BottomBar = () => {
    const navigate = useNavigate();

    const clickNewContract = () => navigate(pages.onboardingContractAdd, { state: { isAdditionalContracts: true } });
    const clickShare = () => navigate(pages.sharing);
    const clickPartner = () => navigate(pages.businessPartnerWerden);

    return (
        <div className="bottom-bar-wrapper">
            <div className="bottom-bar">
                <div className="btm-item">
                    <div className="bottom-bar-icon bell-icon" />
                    <span className="btm-text">Nachrichten</span>
                </div>
                <div className="btm-item" onClick={clickNewContract}>
                    <div className="bottom-bar-icon contract-icon" />
                    <span className="btm-text">Neuer Vertrag</span>
                </div>
                <div className="btm-item" onClick={clickShare}>
                    <div className="bottom-bar-icon share-icon" />
                    <span className="btm-text">Empfehlen</span>
                </div>
                <div className="btm-item" onClick={clickPartner}>
                    <div className="bottom-bar-icon partner-icon" />
                    <span className="btm-text">B-Partner</span>
                </div>
            </div>
        </div>
    );
};

export default BottomBar;
