/**
 * Created by Max Gornostayev on 02/15/22
 *
 * pageId: secureContractsList
 * path: /contracts
 *
 * this is a page that shows added contracts
 */

import React, { useRef, useState } from 'react';
import { observer } from 'mobx-react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Header from '../../../components/V2/Header';
import SearchWidget from '../../../components/elements/SearchWidget';
import ProviderImage from '../../../components/elements/ProviderImage';
import Popup from '../../../components/popups/Popup';
import Alert from '../../../components/popups/Alert';
import Loading from '../../../components/elements/Loading';
import pageUrl from '../../../const/pages';
import config from '../../../config';
import trans from '../../../trans';
import Utils from '../../../lib/Utils';
import '../../../styles/V2/index.scss';
import { Switcher } from '../../../components/V2/Switcher';
import PageWrapper from '../../../components/V2/PageWrapper';

const ContractsListPage = observer(({ DataStore, ContractsStore, UserStore }) => {
    //state variables
    //ref variables
    const refPopup = useRef();
    const refAlert = useRef();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const filterParam = searchParams.get('filter');

    const [isLoading, setIsLoading] = useState(false);
    const [filter, setFilter] = useState(filterParam || 'insurance');
    const [searchText, setSearchText] = useState('');
    const [statusContract, setStatusContract] = useState('');
    const [order, setOrder] = useState('asc');

    //show cashback popup
    const showCashbackPopup = async (contractBrokerStatusValue, contractId) => {
        switch (contractBrokerStatusValue) {
            case 'active_without_fee':
            case 'reject':
                refPopup.current.show();
                break;
            case 'open':
            case 'deactivation_broker':
                await activateBrokerContract(contractId);
                break;
            default:
                break;
        }
        return true;
    };

    //activate contract cashback
    const activateBrokerContract = async (contractId) => {
        setIsLoading(true);
        const res = await ContractsStore.activateBrokerForContracts([contractId]);
        setIsLoading(false);
        if (!res.status) {
            refAlert.current.error(res.msg);
            return false;
        }

        return true;
    };

    //render contracts function
    const renderContract = (contract, index) => {
        const { providerLogoUrl, brokerStatusValue, numberValue, yearlyCostValue, statusTitle, numberDocuments, tariffsArr } = contract;

        const productTitle = DataStore.getProductTypeTitle(contract.productTypeValue);
        const providerTitle = DataStore.getProviderTitle(contract.provider);

        const openContractDetail = () => navigate(pageUrl.secureContractDetail, { state: { contractId: contract.idValue } });
        const openAddDocuments = (e) => {
            e.stopPropagation();
            navigate(pageUrl.secureContractDocuments, { state: { contractId: contract.idValue } });
        };

        return (
            <div className="item bottom-border" key={index} onClick={openContractDetail}>
                <div className="title-row">
                    <div className="item-title">
                        <div className="title-product">{productTitle}</div>
                    </div>
                    <div className="item-title right">
                        <div className="title-product">{Utils.formatPrice(yearlyCostValue)}</div>
                        <div className="subtitle">jährlich</div>
                    </div>
                </div>
                <div className="row">
                    <div className="detail-item provider">
                        <ProviderImage title={providerTitle} imageSrc={providerLogoUrl} isHeaderStyle />
                    </div>
                    <div className="detail">
                        <div className="detail-item">
                            <div>{providerTitle}</div>
                        </div>
                        <div className="detail-item">
                            <div>{trans.t('secureContractsListPage', 'contractNumber')}.:</div>
                            <div>{numberValue || '-'}</div>
                        </div>
                        <div className="detail-item">
                            <div>{trans.t('secureContractsListPage', 'contractStatus')}:</div>
                            <div>{statusTitle || '-'}</div>
                        </div>
                        <div className="detail-item">
                            <div>{trans.t('secureContractsListPage', 'contractDocuments')}:</div>
                            <div>{numberDocuments}</div>
                            <div className="add-documents" onClick={openAddDocuments}>
                                {trans.t('secureContractsListPage', 'btnAddDocuments')}
                            </div>
                        </div>
                        {!!tariffsArr.length && (
                            <div className="detail-item">
                                <div>{trans.t('secureContractsListPage', 'contractTariffs')}:</div>
                                {tariffsArr.map((tariff, index) => (
                                    <div key={index}>{tariff}</div>
                                ))}
                            </div>
                        )}
                    </div>
                </div>
                <div role={'btnCashbackPopup' + contract.idValue} onClick={() => showCashbackPopup(brokerStatusValue, contract.idValue)} className={'cashback-status ' + brokerStatusValue}>
                    <span />
                    {trans.t('secureContractsListPage', 'brokerStatus_' + brokerStatusValue)}
                </div>
            </div>
        );
    };

    const renderModals = () => (
        <>
            <Popup
                ref={refPopup}
                isAutoHeight
                title={trans.t('secureContractsListPage', 'contractPopupTitle')}
                leftBtnTitle={trans.t('secureContractsListPage', 'contractPopupLeftBtnTitle')}
                rightBtnTitle={trans.t('secureContractsListPage', 'contractPopupRightBtnTitle')}
                rightFunc={() => window.open(config.bitrix24Urls.contractPopupUrl, '_blank').focus()}
            >
                <div className="contract-popup-text">
                    {trans.t('secureContractsListPage', 'contractPopupText1')}
                    <br />
                    <br />
                    {trans.t('secureContractsListPage', 'contractPopupText2')}
                </div>
            </Popup>
            <Alert ref={refAlert} />
            <Loading isShow={isLoading} />
        </>
    );

    const contractsToRender = ContractsStore.getFilteredContracts(filter, searchText, statusContract, order);

    return (
        <PageWrapper
            className="contract-list-page"
            header={<Header backButton title="Verträge" />}
            modals={renderModals()}
        >
            <div className="search-wrapper">
                <Switcher
                    firstTitle="Versicherungen"
                    secondTitle="Energie"
                    active={filter === 'insurance' ? 'first' : 'second'}
                    firstFClick={() => {
                        setFilter('insurance');
                    }}
                    secondClick={() => setFilter('energy')}
                />
            </div>
            <div className="search-wrapper">
                <SearchWidget
                    onChange={(searchText, statusContract, order) => {
                        setSearchText(searchText);
                        setStatusContract(statusContract);
                        setOrder(order);
                    }}
                    filterList={DataStore.contractStatusList}
                />
            </div>
            <div className="contracts-wrapper">
                <div className="contracts">{contractsToRender.map((contract, index) => renderContract(contract, index))}</div>
            </div>
        </PageWrapper>
    );
});

export default ContractsListPage;
