/**
 * Created by Max Gornostayev on 05/25/22
 *
 * pageId: secureDashboard
 * path: /dashboard
 *
 * this is a main page that user see after success login
 */

import React, { useMemo } from 'react';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import pageUrl from '../../const/pages';
import Header from '../../components/V2/Header';
import UserCard from '../../components/V2/UserCard';
import ContractsCard from '../../components/V2/DashboardContractsCard';
import DashboardButton from '../../components/V2/DashboardButton';
import businessPartner from '../../assets/V2/businessPartner.svg';
import coin from '../../assets/V2/coin.png';
import '../../styles/V2/index.scss';
import trans from '../../trans';
import PageWrapper from '../../components/V2/PageWrapper';

const DashboardPage = observer(({ UserStore, ContractsStore, DataStore }) => {
    const navigate = useNavigate();
    const { customer } = UserStore;

    const insuranceData = useMemo(() => {
        const insuranceContracts = ContractsStore.getInsuranceContracts(false);

        const insuranceContractsSummary = insuranceContracts.reduce((acc, contract) => {
            acc += contract.yearlyCostValue;
            return acc;
        }, 0);

        return {
            count: insuranceContracts.length,
            totalPrice: insuranceContractsSummary,
        };
    }, [ContractsStore.contracts]);

    const energyData = useMemo(() => {
        const energyContracts = ContractsStore.getStromContracts(false);

        const energyContractsSummary = energyContracts.reduce((acc, contract) => {
            acc += contract.yearlyCostValue;
            return acc;
        }, 0);

        return {
            count: energyContracts.length,
            totalPrice: energyContractsSummary,
        };
    }, [ContractsStore.contracts]);

    const clickInsuranceCard = () => {
        navigate(`${pageUrl.secureContractsList}?filter=insurance`);
    };

    const clickEnergyCard = () => {
        navigate(`${pageUrl.secureContractsList}?filter=energy`);
    };

    const clickNewContract = () => navigate(pageUrl.onboardingContractAdd, { state: { isAdditionalContracts: true } });

    return (
        <PageWrapper
            header={<><Header /><UserCard customer={customer} /></>}
            className="dashboard-background"
        >
            <div className="dashboard-cards-wrapper">
                <ContractsCard
                    title={trans.t('dashboardPage', 'insurance')}
                    contractCount={insuranceData.count}
                    onButtonClick={clickNewContract}
                    isDisabled={!insuranceData.count}
                    onClick={clickInsuranceCard}
                />
                <ContractsCard
                    title={trans.t('dashboardPage', 'energyContract')}
                    contractCount={energyData.count}
                    green
                    isDisabled={!energyData.count}
                    onButtonClick={clickNewContract}
                    onClick={clickEnergyCard}
                />
                <ContractsCard
                    title={trans.t('dashboardPage', 'cashback')}
                    tokensCount={50}
                    yellow
                    onClick={() => navigate(pageUrl.cashback)}
                />
                <ContractsCard
                    title={trans.t('dashboardPage', 'shareCommunity')}
                    description={trans.t('dashboardPage', 'shareCommunityDescription')}
                    brown
                    onClick={() => navigate(pageUrl.sharing)}
                />
                <div className="dashboard-buttons-row">
                    <DashboardButton
                        iconSrc={businessPartner}
                        mainText={<>{trans.t('dashboardPage', 'becomeBusinessPartner')}</>}
                        subText={<>{trans.t('dashboardPage', 'buildSalesStructure')}</>}
                        onClick={() => navigate(pageUrl.businessPartnerWerden)}
                    />
                    <DashboardButton
                        iconSrc={coin}
                        mainText={<>{trans.t('dashboardPage', 'becomeInvestor')}</>}
                        subText={<>{trans.t('dashboardPage', 'buyCompanyTokens')}</>}
                        onClick={() => navigate(pageUrl.businessPartnerWerden)}
                    />
                </div>
            </div>
        </PageWrapper>
    );
});

export default DashboardPage;
