/**
 * Created by Max Gornostayev on 02/15/22
 *
 * pageId: onboardingProducts
 * path: /onboarding/vertrag/extra-typ
 *
 * this is a page show products list to select product
 */

import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useNavigate, useLocation } from 'react-router-dom';
import Header from '../../components/theme/HeaderV2';
import Bottom from '../../components/theme/Bottom';
import BottomLinks from '../../components/theme/BottomLinks';
import ButtonV2 from '../../components/V2/Button';
import SearchInput from '../../components/elements/SearchInput';
import CheckboxList from '../../components/forms/CheckboxList';
import pageUrl from '../../const/pages';
import trans from '../../trans';
import '../../styles/V1/onboarding.scss';
import '../../styles/V1/app.scss';

const ProductsPage = observer(({ DataStore, ContractsStore, UserStore }) => {
    const { contractTemp } = ContractsStore;
    const { productTypeValue } = contractTemp;
    const { isCompany } = UserStore;

    const [productType, setProductType] = useState(productTypeValue);
    const [products, setProducts] = useState([]);
    const [loaded, setLoaded] = useState(false);

    const navigate = useNavigate();
    const { state } = useLocation();

    useEffect(() => {
        if (!loaded) {
            const arr = DataStore.getProductsListByFilter('', isCompany);
            setProducts(arr);
            setLoaded(true);
        }
    }, [loaded, setLoaded]);

    const loadProducts = (val) => {
        const arr = DataStore.getProductsListByFilter(val, isCompany);
        setProducts(arr);
    };

    const next = () => {
        ContractsStore.setProductTypeForAddingContract(productType);
        const contractId = state && state.hasOwnProperty('contractId') && state.contractId;
        navigate(pageUrl.onboardingProviders, { state: { contractId } });
    };

    return (
        <div className="app onboarding-products-page">
            <Header backButton noMenu title={trans.t('onboardingProductsPage', 'h1')} />
            <div className="wrapper">
                <SearchInput onChange={(val) => loadProducts(val)} black />
            </div>
            <div className="content">
                <div className="wrapper-big">
                    {!!productType && (
                        <div className="selected-item">
                            <div className="wrapper">
                                <span>
                                    {trans.t('onboardingProductsPage', 'selectedProductText')}: {DataStore.getProductTypeTitle(contractTemp.productTypeValue)}
                                </span>
                            </div>
                        </div>
                    )}
                    <div className="products">
                        <CheckboxList list={products} onClick={(val) => setProductType(val)} defaultValue={productType} />
                    </div>
                </div>
            </div>
            {productType && (
                <Bottom>
                    <ButtonV2
                        role="btnNext"
                        title={trans.t('onboardingProductsPage', 'btnNext')}
                        onClick={next}
                        green
                    />
                </Bottom>
            )}
            <BottomLinks />
        </div>
    );
});

export default ProductsPage;
